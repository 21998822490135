@import '~@vinhxuan/look-styles/src/variables_before';

html{
  .view-main{
    padding-top: 0;
    font-family: $font-family-base;
  }

  .footer{
    h2{
      color: #8eb747;
      font-size: 24px
    }
    .phone{
      color: #8eb747;
      font-size: 24px
    }
    .about{
      font-size: 14px
    }
    .list{
      & li{
        & .nav-link{
          padding: 4px 0 4px 12px;
        };
        position: relative;
        padding: 0;
        &:before{
          width: 0;
          height: 0;
          content: "";
          border-style: solid;
          border-width: 3px 0 3px 6px;
          border-color: transparent transparent transparent #8eb747;
          position: absolute;
          top: 10px;
          left: 0px;
        }
      }
    }
  }
}
