.pager{
  &.pagination, &[class*="pagination"], &[class*="pagination-"]{
    width: 100%;
    border-radius: 0;
    border: none;
    padding: 0px;
    text-align: center;
    display: flex !important;
    justify-content: center !important;
    li.page-item{
      padding: 0 !important;
      .page-link {
        display: block;
        margin: 0 !important;
      }

      &:not(.disabled) {
        .page-link{
          color: $link-color;
        }

        &.active {
          .page-link{
            background-color: $orange;
            border-color: $orange;
            color: $white !important;
          }
        }
      }
    }
  }
}
