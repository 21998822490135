@import '~bootstrap/scss/functions';
@import '~@vinhxuan/look-styles/src/luke/mixins';
@import '~@vinhxuan/look-styles/src/mixins/grid';
@import '~@vinhxuan/look-styles/src/variables';

.build, .web{
  &:root{
    --link-list-item-wrap1: 70px;
    --link-list-item-max1: 100%;
    @media screen and (max-width: theme-width('md')){
      --link-list-item-wrap1: 60px;
      --link-list-item-max1: 300px;
    }
  }

  .link-item {
    padding: 0 10px !important;
    .item-wrap{
      .item-wrap-1{
        width: var(--link-list-item-wrap1);
      }

      .item-wrap-2{
        max-width: var(--link-list-item-max1);
        width: calc(100% - var(--link-list-item-wrap1));
      }
    }
  }
}
.web{

  @media screen and (max-width: theme-width('md')) {
    .link-item {
      padding: 0 7px !important;

      .link-logo {
        width: 50px !important;
      }
    }
  }

  @media screen and (max-width: theme-width('lg')){
    .link-item {
      .item-wrap {
        max-width: calc(100%);
      }
    }
  }
}
