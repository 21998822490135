@import '~bootstrap/scss/functions';
@import '~react-focal/src/focal.scss';
@import '~@vinhxuan/look-styles/src/variables';
@import '~@vinhxuan/look-styles/src/luke/mixins';
@import '~@vinhxuan/look-styles/src/bootstrap';
@import '~@vinhxuan/look-styles/src/luke/index';
@import '~@vinhxuan/look-styles/src/styles';
@import '~@vinhxuan/look-styles/src/luke/widget';
@import '~@gqlapp/base/colorful/css/styles.scss';
@import '~@vinhxuan/home-client-react/styles';
@import '~nprogress/nprogress.css';
@import '~react-slideshow-image/dist/styles.css';
@import "main";
@import "header";
@import "footer";
@import "pagination";
@import "home";

html, html body, html #__next {
  height: calc(100%);
  min-height: calc(100%) !important;
  font-family: $font-family-base;
}

#nprogress {
  z-index: 9999999999999;
  .bar {
    background: var(--bar-color, red);
  }
}
