@import '~bootstrap/scss/functions';
@import '~@vinhxuan/look-styles/src/luke/mixins';
@import '~@vinhxuan/look-styles/src/mixins/grid';
@import '~@vinhxuan/look-styles/src/variables';
.dashboard-link-view{
  min-height: 43px !important;
  .btn{
    line-height: 31px;
  }
}
.breadcrumb{
  .date-filter{
    &{
      margin: -7px 0;
      line-height: 34px;
      font-size: 14px;
    }

    > span{
      padding-right: 5px;
    }

    .MuiIcon-root{
      font-size: 18px;
      margin: 5px 0 -4px;
    }
    .btn-group{
      padding: 0;
      //margin: -3px 0 3px;
      border: 0;
      cursor: pointer;
      .dropdown-item{
        &::first-letter{
          text-transform: uppercase;
        }
      }
    }

  }
}

@media screen and (max-width: theme-width('md')) {
  .dashboard-icon {
    max-width: 50px;
  }
  .date-filter{
    > span{
      display: none;
    }
    .btn-group{
      &{
        .title {
          display: block;
          &{
            &::first-letter {
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
}
