$white: #fff;
$yellow: #FC9430;
$facebook: #1a0dab;
$orange: #F28721;
$gray: #fafafa;
$light: #F2F3F5;
$color1: #D44B3D;
$color2: #AC3A3A;
$color3: #ced4da;
$color4: #F8F8F8;
$color5: #006621;
$color6: #EFEFEF;
$color7: #212121;
$color8: rgba(21, 21, 21, .7);
$color9: rgba(21, 21, 21, .3);
$color10: #F15433;
$color12: #4E86B9;
$color13: #A709A7;
$color14: #1480B1;
$gray-900: #212121;
$gray-300: #dee2e6;
$green: #528906;
$purple: #703EB4;
$primary: #0066CC;
$success: #006621;
$waring: #FC9430;
$danger: #B10606;
$secondary: #686868;
$dark: #212121;
$light: #ffffff;
$transparent: transparent;
$font-weight-bold: 500 !default;
$font-weight-bolder: 500 !default;
$line-height-base: 1.5 !default;

$body-bg                : #f2f2f2;
$box-shadow-sm     : 0px 1px 1px 0 rgba(69, 65, 78, 0.16);
$box-shadow        : 0px 1px 3px 0 rgba(69, 65, 78, 0.16);
$box-shadow-lg     : 2px 6px 15px 0 rgba(69, 65, 78, 0.16);

$box-shadow-none  : none;
$btn-box-shadow: none;
//$btn-padding-y: 5px;
$input-box-shadow: none;

$link-color: #212121;
$link-hover-decoration: none;
$link-hover-color: #0066CC;

$table-hover-bg: #fafbfc;

$table-border-color: #EFEFEF;
$grid-gutter-mini-width:   5px;
$grid-gutter-small-width:   10px;
$grid-gutter-medium-width:  15px;
$grid-gutter-width:         20px;
$grid-gutter-big-width:     30px;
$grid-gutter-none-width:    0px;

// stylelint-disable value-keyword-case
$font-family-sans-serif:      "Roboto", "Helvetica", "Arial", sans-serif, -apple-system, system-ui, AppleGothic,"Lucida Grande","Arial Unicode MS","Times New Roman";
$font-family-monospace:       SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;

$font-family-base:            $font-family-sans-serif;

$font-size-base:              1rem * .875; // Assumes the browser default, typically `16px`
$font-size-lg:                ($font-size-base * 1.05);
$font-size-xl:                ($font-size-base * 4.05);
$font-size-vl:                ($font-size-base * 5.05);
$font-size-hs:                ($font-size-base * .95);

$hs-font-size:                $font-size-base * 1.28571;
$hv-font-size:                $font-size-base * 1.78571;
$hl-font-size:                $font-size-base * 2.5;

$h1-font-size:                $font-size-base * 2.28571;
$h2-font-size:                $font-size-base * 1.5;
$h3-font-size:                $font-size-base * 1.142858;
$h4-font-size:                $font-size-base * 1;
$h5-font-size:                $font-size-base * .85714286;
$h6-font-size:                $font-size-base * .7857142;
$border-color:                #f2f2f2;

$theme-colors:(
  "secondary" : $secondary,
  "success"   : $success,
  "facebook"  : $facebook,
  "green"     : $green,
  "purple"    : $purple,
  "orange"    : $orange,
  "white"     : $white,
  "yellow"    : $color1,
  "dark"      : $dark,
  "light"     : $light
);


$h-sizes: (
        hs: $hs-font-size,
        hv: $hv-font-size,
        hl: $hl-font-size,
        h1: $h1-font-size,
        h2: $h2-font-size,
        h3: $h3-font-size,
        h4: $h4-font-size,
        h5: $h5-font-size,
        h6: $h6-font-size
);

$text-sizes: (
        hs: $hs-font-size,
        hv: $hv-font-size,
        hl: $hl-font-size,
        h6: $h6-font-size,
        h5: $h5-font-size,
        h4: $h4-font-size,
        h3: $h3-font-size,
        h2: $h2-font-size,
        h1: $h1-font-size
);


$lh-sizes: (
        1: $h1-font-size,
        2: $h2-font-size,
        3: $h3-font-size,
        4: $h4-font-size,
        5: $h5-font-size,
        6: $h6-font-size
);

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  170;

//// Customize the light and dark text colors for use in our YIQ color contrast function.
//$yiq-text-dark:             $gray-900 !default;
//$yiq-text-light:            $white !default;





$shadows: (
        small: $box-shadow-sm,
        normal: $box-shadow,
        large: $box-shadow-lg,
        none: $box-shadow-none,
);


$borders: (
        0: 0px,
        1: 1px,
        2: 2px,
        3: 3px,
        4: 4px,
);